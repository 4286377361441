var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.data.properties.filed_content != 'Hide')?_c('div',{style:(_vm.getElementStyle)},[_c('el-row',{attrs:{"align":"middle"}},[_c('div',[(
          !_vm.data.properties.hideLabel &&
          _vm.data.properties.filed_content != 'Hide' &&  _vm.hasLabel 
        )?_c('el-col',{staticStyle:{"white-space":"nowrap"},attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 8 : _vm.data.description ? 8 : 24}},[_vm._v(" "+_vm._s(_vm.label||_vm.data.label)+" "),(_vm.data.validations.required && _vm.hasLabel && !_vm.isView )?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" * ")]):_vm._e(),(_vm.data.description)?_c('span',[_c('i',{staticClass:"el-icon-info",attrs:{"title":_vm.data.description}})]):_vm._e()]):_vm._e()],1),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 12 : 24}},[(_vm.data.properties.filed_content != 'Hide')?_c('div',{staticClass:"icon-block",class:{ 'highlighted-border': _vm.highlight },style:(_vm.getStyle)},[_c('div',{staticClass:"icon avatar-uploader",staticStyle:{"width":"100%"},on:{"click":_vm.openSignature}},[(_vm.data.source || _vm.data.default_value)?_c('img',{staticClass:"sign_img",attrs:{"src":_vm.data.source || _vm.data.default_value,"alt":"signature"}}):_vm._e(),(this.form[this.data.key] && !_vm.data.source)?_c('img',{staticClass:"sign_img",attrs:{"src":this.form[this.data.key] || _vm.data.default_value,"alt":"signature"}}):_vm._e(),(
              !_vm.data.source &&
              !_vm.data.default_value &&
              !this.form[this.data.key] &&
              _vm.data.properties.filed_content != 'Hide' 
            )?_c('div',{staticStyle:{"margin-top":"15px"}},[_vm._v(" signature "),_c('i',{staticClass:"el-icon-edit"})]):_vm._e()])]):_vm._e()]),_c('el-col',{attrs:{"span":_vm.showLabel && !_vm.isDefalutPosq ? 12 : 24}},[_c('dialog-component',{attrs:{"visible":_vm.signatureDialogVisible,"title":'Signature'},on:{"before-close":function($event){_vm.signatureDialogVisible=false}}},[_c('SignaturePad',{attrs:{"field":_vm.data}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"capitalize",attrs:{"type":"primary","disabled":_vm.disableButton},on:{"click":_vm.saveSign}},[_vm._v("Save Signature")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }